const newsMap = {
    "upcoming-events": "Upcoming Events",
    "online-lectures": "YouTube Lectures",
    "video-interviews": "Video Interviews",
    reviews: "News/Media",
    "published-articles": "Published Articles",
    "leed-well": "World's first Double Platinum LEED and WELL",
};

export default newsMap;
